import React, { FC, useEffect } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import automaticAi_1 from "../../images/monitoringScreens/automatic-ai_1.jpg";
import automaticAi_2 from "../../images/monitoringScreens/automatic-ai_2.jpg";
import automaticAi_3 from "../../images/monitoringScreens/automatic-ai_3.jpg";
import automaticAi_4 from "../../images/monitoringScreens/automatic-ai_4.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";
import { navigate } from "gatsby";

const AutomaticAIMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  useEffect(() => {
    navigate('/');
  }, []);
  
  return (
    <div className="content-wrapper">
      <PageHero
        title="Automatic AI monitoring"
        subtitle="Hexowatch will look for any types of changes (visual, content, source code and technology monitoring)."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 automatic-ai-onitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                controls
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
                poster={automaticAi_1}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Automatic_AI_monitor_-_Walkthrough_1598174280.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
                poster={automaticAi_1}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Automatic_AI_monitor_-_Walkthrough_1598174280.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Automatic AI monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img
                src={automaticAi_1}
                alt="Select automatic AI monitoring tool"
              />
            </div>
            <p className="mb">
              The automatic AI monitor keeps an eye for any changes on a page at
              a visual, content, source code and technology level. This is ideal if
              you're looking for a quick way to check a page and want to get
              alerted when any type of change occurs.
            </p>

            <div className="img-block">
              <img src={automaticAi_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check.
            </p>

            <div className="img-block">
              <img
                src={automaticAi_3}
                alt="Choose the frequency of the checks, your preferred notification channels"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img
                src={automaticAi_4}
                alt="Click start monitoring to get started"
              />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you when any changes are detected.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default AutomaticAIMonitoring;
